import React, { useEffect, lazy, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import { getValue, EMAIL_PATTERN, isEmpty, isValueValid, ALPHABETS_ONLY, ALPHABETS_ONLY_WITH_SPACE, ALPHABETS_NUMBER_ONLY } from "utilities/app.js";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import squadService from "../../services/squad";
import userService from "../../services/user";
import masterService from "../../services/apiService";
import { useForm } from "react-hook-form";
import CustomInput from "components/CustomInput/CustomInput.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DataGrid } from '@material-ui/data-grid';
import Switch from "@material-ui/core/Switch";
import { findLast } from "lodash";
import moment from "moment";
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ReactTable = lazy(() => import(/* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'));
const ImageUpload = lazy(() => import(/* webpackChunkName: "image-upload" */ 'components/CustomUpload/ImageUpload.js'));
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const SquadContainer = props => {

    const [data, setData] = React.useState([]);
    const [activeData, setActiveData] = React.useState({});
    const [exportData,setExportData] =useState([])
    const [master, setMaster] = React.useState({});
    const [selectedColor, setSelectedColor] = React.useState("");
    const [selectedSport, setSelectedSport] = React.useState("");
    const [selectedPosition, setSelectedPosition] = React.useState("");
    const { register, handleSubmit, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, reset: reset2 } = useForm();
    const [classicModal, setClassicModal] = React.useState(false);
    const [classicModalAdd, setClassicModalAdd] = React.useState(false);
    const [profile_pic, setProfilePic] = React.useState(null);
    const [tables, setTables] = React.useState([])
    const [user_profile, setUserProfile] = React.useState([])
    const [user_pages, setUserPages] = React.useState([])
    const [user_squads, setUserSquads] = React.useState([])
    const classes = useStyles();
    useEffect(() => {
        (async () => {
            var squads = await userService.getUserList();
            if (squads && squads.data) {
                console.log('squads: ', squads);
                 setExportData(squads.data)
                setData(squads.data.map((prop, key) => {
                    return {
                        id: key,
                        name: prop.name,
                        idcode: prop.id,
                        profile_rank: prop.profile_rank,
                        username: prop.username,
                        is_email_verified:  (<FormControlLabel
                            control={
                              <Switch
                                checked={prop.is_email_verified ? true : false}
                                onChange={event => changeEmailVerified({ status: prop.is_email_verified == "1" ? 0 : 1, user_id: prop.id })}
                                value="checkedA"
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                
                          />),
                        status:(<FormControlLabel
                            control={
                              <Switch
                                checked={prop.status == 1 ? false : true}
                                onChange={event => changeUserStatus({ status: prop.status == "1" ? 0 : 1, user_id: prop.id })}
                                value="checkedA"
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                
                          />),
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={async () => {
                                       

                                            var user_details = await userService.getUserDetails({user_id: prop.id});
                                       console.log("user stats ==>", user_details);
                                       var user_stats = JSON.parse(user_details.data.stats);
                                       console.log("userstats ==>", user_stats);
                                       var tables = user_stats.map(detail => {
                                           var cols = detail.data.columns.map((col, x) => {
                                               if(x == 0){

                                                   return { field: col.name, headerName: col.name, width: 180, editable: false }
                                               } else {
                                                return { field: col.id, headerName: col.name, width: 150, editable: true }
                                               }
                                           })
                                           
                                           var rows = detail.data.values.map((val, i) => {
                                               console.log("val =>", val)
                                               var obj = {}
                                               obj.id = i
                                               val.map((v, j) => {
                                                   obj[cols[j].field] = v
                                               })
                                               return obj;
                                           })
                                           return {cols, rows, title: detail.title};
                                       })
                                       var squad_tables = []
                                       var squad_cols = [{field: "squad_name", headerName: "Squad Name", width: 180, editable: false},
                                       {field: "verified_step", headerName: "Verified Step", width: 180, editable: false}]
                                       var squad_rows = user_details.data.squads.map((sq, i) => {
                                           return{
                                               id: i,
                                               squad_name: sq.name,
                                               verified_step: sq.profile_rank
                                           }
                                       })
                                       squad_tables.push({squad_cols, squad_rows})
                                       var page_tables = []
                                       var page_cols = [{field: "page_name", headerName: "Page Name", width: 180, editable: false}]
                                       var page_rows = user_details.data.pages.map((sq, i) => {
                                           return{
                                               id: i,
                                               page_name: sq.page_name
                                           }
                                       })
                                       page_tables.push({page_cols, page_rows})
                                       setTables(tables);
                                       setUserProfile(user_details.data.details)
                                       setUserPages(page_tables)
                                       setUserSquads(squad_tables)
                                       console.log("tables ==>", tables);
                                        reset({
                                            name:user_details.data.details.name,
                                            username: user_details.data.details.username,
                                            email: user_details.data.details.email,
                                            user_id: prop.id,
                                            dob: formatDate(new Date(user_details.data.details.dob))
                                        })
                                        // console.log("props in edit ==>", prop);
                                        setActiveData({ ...user_details.data.details });
                                        setSelectedColor(user_details.data.details.colour_id);
                                        setSelectedSport(user_details.data.details.sport_id);
                                        setSelectedPosition(user_details.data.details.position_id);
                                        // setSelectedLeague(prop.league_id ? prop.league_id : "");
                                        // setSelectedAdminList(prop.admin_ids.split(","))
                                        setClassicModal(true)
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <Edit />
                                </Button>{" "}
                            </div>
                        )
                    };
                }));
            }

            var masterData = await masterService.getMasterData()
            console.log("masterData ==>", masterData);
            if (masterData && masterData.data) {
                setMaster(masterData.data)
            }
        })()
    }, [])
    useEffect(() => {
        console.log("activedata ==>", activeData);
    }, [activeData])
    const reload = async () => {
        var squads = await userService.getUserList()
            console.log("resp ==>", squads);
            if (squads && squads.data) {
                setData(squads.data.map((prop, key) => {
                    console.log("prop ==>", prop);
                    return {
                        id: key,
                        name: prop.name,
                        idcode: prop.id,
                        profile_rank: prop.profile_rank,
                        username: prop.username,
                        is_email_verified:  (<FormControlLabel
                            control={
                              <Switch
                                checked={prop.is_email_verified ? true : false}
                                onChange={event => changeEmailVerified({ status: prop.is_email_verified == "1" ? 0 : 1, user_id: prop.id })}
                                value="checkedA"
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                
                          />),
                        status:(<FormControlLabel
                            control={
                              <Switch
                                checked={prop.status == 1 ? false : true}
                                onChange={event => changeUserStatus({ status: prop.status == "1" ? 0 : 1, user_id: prop.id })}
                                value="checkedA"
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                
                          />),
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={async () => {
                                       

                                            var user_details = await userService.getUserDetails({user_id: prop.id});
                                       console.log("user stats ==>", user_details);
                                       var user_stats = JSON.parse(user_details.data.stats);
                                       console.log("userstats ==>", user_stats);
                                       var tables = user_stats.map(detail => {
                                           var cols = detail.data.columns.map((col, x) => {
                                               if(x == 0){

                                                   return { field: col.name, headerName: col.name, width: 180, editable: false }
                                               } else {
                                                return { field: col.id, headerName: col.name, width: 150, editable: true }
                                               }
                                           })
                                           
                                           var rows = detail.data.values.map((val, i) => {
                                               console.log("val =>", val)
                                               var obj = {}
                                               obj.id = i
                                               val.map((v, j) => {
                                                   obj[cols[j].field] = v
                                               })
                                               return obj;
                                           })
                                           return {cols, rows, title: detail.title};
                                       })
                                       var squad_tables = []
                                       var squad_cols = [{field: "squad_name", headerName: "Squad Name", width: 180, editable: false},
                                       {field: "verified_step", headerName: "Verified Step", width: 180, editable: false}]
                                       var squad_rows = user_details.data.squads.map((sq, i) => {
                                           return{
                                               id: i,
                                               squad_name: sq.name,
                                               verified_step: sq.profile_rank
                                           }
                                       })
                                       squad_tables.push({squad_cols, squad_rows})
                                       var page_tables = []
                                       var page_cols = [{field: "page_name", headerName: "Page Name", width: 180, editable: false}]
                                       var page_rows = user_details.data.pages.map((sq, i) => {
                                           return{
                                               id: i,
                                               page_name: sq.page_name
                                           }
                                       })
                                       page_tables.push({page_cols, page_rows})
                                       setTables(tables);
                                       setUserProfile(user_details.data.details)
                                       setUserPages(page_tables)
                                       setUserSquads(squad_tables)
                                       console.log("tables ==>", tables);
                                        reset({
                                            name:user_details.data.details.name,
                                            username: user_details.data.details.username,
                                            email: user_details.data.details.email,
                                            user_id: prop.id,
                                            dob: formatDate(new Date(user_details.data.details.dob))
                                        })
                                        // console.log("props in edit ==>", prop);
                                        setActiveData({ ...user_details.data.details });
                                        setSelectedColor(user_details.data.details.colour_id);
                                        setSelectedSport(user_details.data.details.sport_id);
                                        setSelectedPosition(user_details.data.details.position_id);
                                        // setSelectedLeague(prop.league_id ? prop.league_id : "");
                                        // setSelectedAdminList(prop.admin_ids.split(","))
                                        setClassicModal(true)
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <Edit />
                                </Button>{" "}
                            </div>
                        )
                    };
                }));
            }
    }
    const changeEmailVerified = async (data) => {
        props.loaderActions.showLoader();
        var resp = await userService.changeVerified(data)
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message)
        reload();
    }
    const changeUserStatus = async (data) => {
        props.loaderActions.showLoader();
        var resp = await userService.changeUserStatus(data)
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message)
        reload();
    }
    const getAge = (birthDate) => {
        console.log("date ==>", birthDate);
        var today = new Date();
        birthDate = new Date(birthDate);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    const onSubmit = async (data) => {
        console.log("submit data ==>", data, getAge(data.dob));
        if (getAge(data.dob) <= 12) {
            props.toastComponentAction.showErrorMessage(true, "Age should be more than 12 years.")
        } else {
            var final = new FormData();
            final.append("name", data.name);
            final.append("username", data.username);
            final.append("email", data.email);
            final.append("user_id", activeData.id);
            final.append("sport_id", selectedSport);
            final.append("colour_id", selectedColor);
            final.append("position_id", selectedPosition);
            if(data.password.length > 0){

                final.append("password", data.password);
            }
            final.append("dob", moment(data.dob).format("YYYY-MM-DD HH:mm:ss"));
            console.log("badge ==>", profile_pic);
            if (profile_pic) {
                final.append("profile_photo", profile_pic)
            }
            try {
                props.loaderActions.showLoader();
                var resp = await userService.editUser(final);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModal(false)
            } catch (err) {
                console.log("err ==>", err);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    const onSubmitAdd = async (data) => {
        console.log("submit data ==>", data, getAge(data.dob));
        if (isEmpty(selectedSport)) {
            props.toastComponentAction.showErrorMessage(true, "Select sport is compulsary.")
        } else if (isEmpty(selectedColor)) {
            props.toastComponentAction.showErrorMessage(true, "Select color is compulsary.")
        } else if (isEmpty(selectedPosition)) {
            props.toastComponentAction.showErrorMessage(true, "Select position is compulsary.")
        }else if (isEmpty(data.dob)) {
            props.toastComponentAction.showErrorMessage(true, "Date Of Birth is compulsary.")
        } else if (getAge(data.dob) <= 12) {
            props.toastComponentAction.showErrorMessage(true, "Age should be more than 12 years.")
        }  else {
            var final = new FormData();
            final.append("name", data.name);
            final.append("username", data.username);
            final.append("email", data.email);
            final.append("password", data.password);
            final.append("sport_id", selectedSport);
            final.append("colour_id", selectedColor);
            final.append("position_id", selectedPosition);
            final.append("dob", moment(data.dob).format("YYYY-MM-DD HH:mm:ss"));
            console.log("badge ==>", profile_pic);
            if (profile_pic) {
                final.append("profile_photo", profile_pic)
            }
            try {
                props.loaderActions.showLoader();
                var resp = await userService.createUser(final);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModalAdd(false)
            } catch (err) {
                console.log("err ==>", err);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    // const stat_columns = [
    //     { field: 'name', headerName: 'Name', width: 180, editable: true },
    //     { field: 'age', headerName: 'Age', type: 'number', editable: true }
    //   ];
      
    //   const rows = [
    //     {
    //       id: 1,
    //       name: "asdas",
    //       age: 25
    //     },
    //     {
    //       id: 2,
    //       name: "aasd",
    //       age: 36
    //     }
    //   ];
    const onEditCell = async (data) => {
        console.log("cell change ==>", data);
        var obj = {}
        obj.squad_id = data.field
        obj.stats = JSON.stringify({field: data.id, value: data.props.value, user_id: activeData.id})
        try{
            props.loaderActions.showLoader();
            var resp = await squadService.editSquadStat(obj);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showSuccessMessage(true, resp.message)
            console.log("resp ==>", resp);
        } catch(err){
            props.loaderActions.hideLoader();
            props.toastComponentAction.showErrorMessage(true, err.response.data.message)
        }
    }

    let headerColumn = [
        { title: "Id", key: "id" },
        { title: "Username", key: "username" },
        { title: "Name", key: "name" },
        { title: "Email", key: "email" },
        { title: "Role", key: "position" },
        { title: "DOB", key: "dob" },
    ];
    const userData = exportData.map((element,index)=>{
        return [
            {
                value:element.id ?? ""
            },{
                value:element.username ?? ""
            },{
                value:element.name ?? ""
            },{
                value:element.email ?? ""
            },{
            value:element.position ?? ""
        },{
            value:element.dob ? moment(element.dob).format("DD-MMM-YYYY"):"",
        }
    ]
    })
    const multiDataSet=[{
        columns:headerColumn,
        data:userData
    }]
    return (
        <>
            <GridContainer
                justify="space-between" // Add it here :)
                container
            >
                <GridItem>
                </GridItem>
                <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={(e) => {
                            setSelectedColor("");
                            setSelectedSport("");
                            setSelectedPosition("");
                            setClassicModalAdd(true)
                            setProfilePic("")
                            setActiveData({})
                            reset2()
                        }}>
                            Add User
              </Button>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                            <h4 className={classes.cardIconTitle}>User List</h4>
                            <ExcelFile 
                             filename="UserDetailReport" 
                              element={  <Button style={{marginTop:"20px"}}>Export</Button> }>
                             <ExcelSheet dataSet={multiDataSet} name="UserDetailReport"/>
                             </ExcelFile>
                             </div>
</CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "ID Code",
                                        accessor: "idcode"
                                    },
                                    {
                                        Header: "Name",
                                        accessor: "name"
                                    },
                                    {
                                        Header: "Username",
                                        accessor: "username"
                                    },
                                    {
                                        Header: "Verified Step",
                                        accessor: "profile_rank"
                                    },
                                    {
                                        Header: "Email Verified",
                                        accessor: "is_email_verified"
                                    },
                                    {
                                        Header: "Block User",
                                        accessor: "status"
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "actions"
                                    }
                                ]}
                                data={data}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/*Edit Model Start*/}
            <Dialog
                fullWidth
                open={classicModal}
                keepMounted
                onClose={() => setClassicModal(false)}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModal(false)}
                    
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>User Details</p>
                </DialogTitle>
                {Object.keys(activeData).length > 0 ? <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <ImageUpload
                            defaultPic={activeData.profile_photo}
                            avatar
                            addButtonProps={{
                                color: "rose",
                                round: true
                            }}
                            onChangeFile={(file) => {
                                console.log("file ==>", file);
                                setProfilePic(file)
                            }}
                            changeButtonProps={{
                                color: "rose",
                                round: true
                            }}
                            removeButtonProps={{
                                color: "danger",
                                round: true
                            }}
                            button_text="Add Squad Badge"
                        />
                        <CustomInput
                            name="name"
                            inputrefs={register({ required: true })}
                            labelText="Name"
                            id="name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.name}`
                            }}
                        />
                           <CustomInput
                            name="email"
                            inputrefs={register({ required: true })}
                            labelText="Email"
                            id="email"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.email}`
                            }}
                        />
                         <CustomInput
                            name="username"
                            inputrefs={register({ required: true })}
                            labelText="Username"
                            id="username"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.username}`
                            }}
                        />
                         <CustomInput
                            name="dob"
                            inputrefs={register({ required: true })}
                            labelText="Date Of Birth"
                            id="dob"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "date",
                                autoComplete: "off",
                                defaultValue:  formatDate(new Date(activeData.dob))
                            }}
                        />
                         <CustomInput
                            name="password"
                            inputrefs={register({ required: false })}
                            labelText="Password"
                            id="password"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off"
                            }}
                        />
                        <CustomInput
                            name="profile_rank"
                            inputrefs={register({ required: true })}
                            labelText="Verified Step"
                            id="profile_rank"
                            formControlProps={{
                                fullWidth: true
                            }}
                            
                            inputProps={{
                                type: "number",
                                autoComplete: "off",
                                defaultValue: `${activeData.profile_rank}`,
                                disabled: true
                            }}
                        />
                         <CustomInput
                            name="follower_cnt"
                            inputrefs={register({ required: true })}
                            labelText="Followers Count"
                            id="follower_cnt"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "number",
                                autoComplete: "off",
                                defaultValue: `${activeData.follower_cnt}`,
                                disabled: true
                            }}
                        />
                         <CustomInput
                            name="user_id"
                            inputrefs={register({ required: true })}
                            labelText="ID Code"
                            id="user_id"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "number",
                                autoComplete: "off",
                                defaultValue: `${activeData.id}`,
                                disabled: true
                            }}
                        />
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Color</InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedColor}
                                onChange={(e) => {
                                    setSelectedColor(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.colours && master.colours.length > 0 ?
                                    master.colours.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Sport</InputLabel>
                            <Select
                                required
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedSport}
                                onChange={(e) => {
                                    setSelectedSport(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.sports && master.sports.length > 0 ?
                                    master.sports.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl} style={{marginBottom: "30px"}}>

                            <InputLabel>Select Position</InputLabel>
                            <Select
                                required
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedPosition}
                                onChange={(e) => {
                                    setSelectedPosition(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.positions && master.positions.length > 0 ?
                                    master.positions.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.sport_name+ " " +art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                            </FormControl>
                        <h2>Squads:</h2>
                        {user_squads && user_squads.length > 0 ?
                        user_squads.map((table, i) => {
                            return <div key={i}>
                            <p>{table.title}</p> 
                            <div style={{ height: 300, width: '100%', marginBottom:"20px" }}>
                            <DataGrid rows={table.squad_rows} columns={table.squad_cols} pageSize={table.squad_rows.length}/>
                            </div></div>
                        })
                        :null}
                         <h2>Pages:</h2>
                        {user_pages && user_pages.length > 0 ?
                        user_pages.map((table, i) => {
                            return <div key={i}>
                            <p>{table.title}</p> 
                            <div style={{ height: 300, width: '100%', marginBottom:"20px" }}>
                            <DataGrid rows={table.page_rows} columns={table.page_cols} pageSize={table.page_rows.length}/>
                            </div></div>
                        })
                        :null}
                        <h2>Stats:</h2>
                        {tables && tables.length > 0 ?
                        tables.map((table, i) => {
                            return <div key={i}>
                            <p>{table.title}</p> 
                            <div style={{ height: 300, width: '100%', marginBottom:"20px" }}>
                            <DataGrid rows={table.rows} columns={table.cols} pageSize={table.rows.length} onEditCellChangeCommitted={onEditCell} />
                            </div></div>
                        })
                        :null}
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                        {
                            activeData ?
                                <Button type="submit" color="transparent">Submit</Button> : ''
                        }
                        <Button
                            onClick={() => setClassicModal(false)}
                            color="danger"
                            simple
                        >
                            Close
     </Button>
                    </DialogActions>
                </form> : null}

            </Dialog>
            {/*Edit Model End*/}
            {/*Add Model Start*/}
            <Dialog
                fullWidth
                open={classicModalAdd}
                keepMounted
                onClose={() => setClassicModalAdd(false)}
                aria-labelledby="form-dialog-title"

            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModalAdd(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Add User</p>
                </DialogTitle>
                <form onSubmit={handleSubmit2(onSubmitAdd)}>
                    <DialogContent>

                    <ImageUpload
                            defaultPic={activeData.profile_photo}
                            avatar
                            addButtonProps={{
                                color: "rose",
                                round: true
                            }}
                            onChangeFile={(file) => {
                                console.log("file ==>", file);
                                setProfilePic(file)
                            }}
                            changeButtonProps={{
                                color: "rose",
                                round: true
                            }}
                            removeButtonProps={{
                                color: "danger",
                                round: true
                            }}
                            button_text="Add Photo"
                        />
                        <CustomInput
                            name="name"
                            inputrefs={register2({ required: true })}
                            labelText="Name"
                            id="name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off"
                            }}
                        />
                           <CustomInput
                            name="email"
                            inputrefs={register2({ required: true })}
                            labelText="Email"
                            id="email"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "email",
                                autoComplete: "off"
                            }}
                        />
                          <CustomInput
                            name="username"
                            inputrefs={register2({ required: true })}
                            labelText="Username"
                            id="username"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off"
                            }}
                        />
                         <CustomInput
                            name="password"
                            inputrefs={register2({ required: true })}
                            labelText="Password"
                            id="password"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "password",
                                autoComplete: "off"
                            }}
                        />
                          <CustomInput
                            name="dob"
                            inputrefs={register2({ required: true })}
                            labelText="Date Of Birth"
                            id="dob"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "date",
                                autoComplete: "off",
                                defaultValue:  formatDate(new Date())
                            }}
                        />
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Color</InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedColor}
                                onChange={(e) => {
                                    setSelectedColor(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.colours && master.colours.length > 0 ?
                                    master.colours.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>

                            <InputLabel>Select Sport</InputLabel>
                            <Select
                                required
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedSport}
                                onChange={(e) => {
                                    setSelectedSport(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.sports && master.sports.length > 0 ?
                                    master.sports.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl} style={{marginBottom: "30px"}}>

                            <InputLabel>Select Position</InputLabel>
                            <Select
                                required
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}

                                value={selectedPosition}
                                onChange={(e) => {
                                    setSelectedPosition(e.target.value)
                                }}
                                inputProps={{
                                    name: "simpleSelect"
                                }}

                            >
                                {master.positions && master.positions.length > 0 ?
                                    master.positions.map(art => {
                                        return <MenuItem
                                            key={art.value}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            value={art.value}
                                        >
                                            {art.sport_name+ " " +art.label}
                                        </MenuItem>
                                    })
                                    : null}
                            </Select>
                        </FormControl>
                    </DialogContent>

                    <DialogActions className={classes.modalFooter}>
                        <Button type="submit" color="transparent">Submit</Button>
                        <Button
                            onClick={() => setClassicModalAdd(false)}
                            color="danger"
                            simple
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>


            </Dialog>
            {/*Add Model End*/}
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
        squadAction: bindActionCreators(squadAction, dispatch),
        loaderActions: bindActionCreators(loaderActions, dispatch),
        toastComponentAction: bindActionCreators(toastComponentAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SquadContainer);
