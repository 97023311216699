import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MESSAGE_TIMEOUT, DEFAULT_ERROR_MESSAGE } from "../utilities/app";
import Snackbar from "components/Snackbar/Snackbar";
import Close from "@material-ui/icons/Close";

const ToastComponent = (props) => {
console.log("props in toast ==>", props);


    return (
        <><div>
            {props.isSuccessMessage &&
            <>
                {/* <div className="alert alert-info" role="alert">
                    {props.message}
                </div> */}
                 <Snackbar
                 message={
                    props.message
                 }
                 color="success"
               />
               </>
            }
            {props.isErrorMessage &&
                // <div className="alert alert-danger" role="alert">
                //     {props.message}
                // </div>
                <Snackbar
                message={
                    props.message
                }
                color="danger"
              />
            }
            {props.isWarningMessage &&
                // <div className="alert alert-warning" role="alert">
                //     {props.message}
                // </div>
                 <Snackbar
                 message={
                    props.message
                 }
                 color="warning"
               />
            }
            {props.isDefaultErrorMessage &&
                // <div className="alert alert-danger" role="alert">
                //     {DEFAULT_ERROR_MESSAGE}
                // </div>
                <Snackbar
                message={
                    DEFAULT_ERROR_MESSAGE
                }
                color="danger"
              />
            }
            </div>
        </>
    );
};

ToastComponent.propTypes = {

}

function mapStateToProps(state) {
    return {
        isSuccessMessage: state.toastComponent.isSuccessMessage,
        isErrorMessage: state.toastComponent.isErrorMessage,
        isWarningMessage: state.toastComponent.isWarningMessage,
        isDefaultErrorMessage: state.toastComponent.isDefaultErrorMessage,
        message: state.toastComponent.message
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ToastComponent);