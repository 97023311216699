import React, { useEffect, lazy, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import { getValue, EMAIL_PATTERN, isEmpty, isValueValid, ALPHABETS_ONLY, ALPHABETS_ONLY_WITH_SPACE, ALPHABETS_NUMBER_ONLY } from "utilities/app.js";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import squadService from "../../services/event";
import masterService from "../../services/apiService";
import userService from "../../services/user";
import { useForm } from "react-hook-form";
import CustomInput from "components/CustomInput/CustomInput.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
const ReactTable = lazy(() => import(/* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'));
const ImageUpload = lazy(() => import(/* webpackChunkName: "image-upload" */ 'components/CustomUpload/ImageUpload.js'));
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertstyles);
const SquadContainer = props => {
    const [alert, setAlert] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [activeData, setActiveData] = React.useState({});
    const [master, setMaster] = React.useState({});
    const [leagues, setLeagues] = React.useState([]);
    const [adminList, setAdminList] = React.useState([]);
    const [squads, setSquads] = React.useState([]);
    const [selectedSquad, setSelectedSquad] = React.useState("");
    const [selectedAdminList, setSelectedAdminList] = React.useState([]);
    const [selectedColor, setSelectedColor] = React.useState("");
    const [selectedEventType, setSelectedType] = React.useState("");
    const [selectedSport, setSelectedSport] = React.useState("");
    const [selectedLeague, setSelectedLeague] = React.useState("");
    const { register, handleSubmit, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, reset: reset2 } = useForm();
    const [classicModal, setClassicModal] = React.useState(false);
    const [classicModalAdd, setClassicModalAdd] = React.useState(false);
    const [profile_pic, setProfilePic] = React.useState(null);
    const classes = useStyles();
    const classesalert = useStylesAlert();
    useEffect(() => {
        (async () => {
            var squads = await squadService.getSquadList()
            console.log("resp ==>", data);
            if (squads && squads.data) {
                setData(squads.data.map((prop, key) => {
                    console.log("prop ==>", prop);
                    return {
                        id: key,
                        squad: prop.name,
                        date: new Date(prop.date).toLocaleDateString(),
                        event_type: prop.event_type_name,
                        sport_name: prop.sport_name,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        reset({
                                            date: formatDate(new Date(prop.date)),
                                            home_team_name: prop.home_team_name,
                                            away_team_name: prop.away_team_name,
                                            meet_time: formatDate2(new Date(prop.meet_time)),
                                            start_time: formatDate2(new Date(prop.start_time)),
                                            finish_time:formatDate2(new Date(prop.finish_time)),
                                            location: prop.location,
                                            info: prop.info,
                                            fees: prop.fees
                                        })
                                        console.log("props in edit ==>", prop);
                                        setActiveData({ ...prop });
                                        // setSelectedColor(prop.color_id);
                                         setSelectedSquad(prop.squad_id);
                                         setSelectedType(prop.type_id);
                                        // setSelectedLeague(prop.league_id ? prop.league_id : "");
                                        // setSelectedAdminList(prop.admin_ids.split(","))
                                        setClassicModal(true)
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <Edit />
                                </Button>{" "}
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => warningWithConfirmAndCancelMessage({id: prop.id})}
                                    color="danger"
                                    className="remove"
                                >
                                    <Close />
                                </Button>{" "}
                            </div>
                        )
                    };
                }));
            }

            var masterData = await masterService.getMasterData()
            console.log("masterData ==>", masterData);
            if (masterData && masterData.data) {
                setMaster(masterData.data)
            }
            var leagueData = await masterService.getLeagueList()
            console.log("leagueData ==>", leagueData);
            if (leagueData && leagueData.data) {
                setLeagues(leagueData.data)
            }
            var squadData = await squadService.getSquadListReal()
            console.log("squadData ==>", squadData);
            if (squadData && squadData.data) {
                setSquads(squadData.data)
            }
            var userData = await userService.getUserList()
            console.log("userData ==>", userData);
            if (userData && userData.data) {
                setAdminList(userData.data)
            }
        })()
    }, [])
    useEffect(() => {
        console.log("activedata ==>", activeData);
    }, [activeData])
    const reload = async () => {
        var squads = await squadService.getSquadList()
        console.log("resp ==>", data);
        if (squads && squads.data) {
            setData(squads.data.map((prop, key) => {
                console.log("prop ==>", prop);
                return {
                    id: key,
                    squad: prop.name,
                    date: new Date(prop.date).toLocaleDateString(),
                    event_type: prop.event_type_name,
                    sport_name: prop.sport_name,
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    reset({
                                        date: formatDate(new Date(prop.date)),
                                        home_team_name: prop.home_team_name,
                                        away_team_name: prop.away_team_name,
                                        meet_time: formatDate2(new Date(prop.meet_time)),
                                        start_time: formatDate2(new Date(prop.start_time)),
                                        finish_time:formatDate2(new Date(prop.finish_time)),
                                        location: prop.location,
                                        info: prop.info,
                                        fees: prop.fees
                                    })
                                    console.log("props in edit ==>", prop);
                                    setActiveData({ ...prop });
                                    // setSelectedColor(prop.color_id);
                                     setSelectedSquad(prop.squad_id);
                                     setSelectedType(prop.type_id);
                                    // setSelectedLeague(prop.league_id ? prop.league_id : "");
                                    // setSelectedAdminList(prop.admin_ids.split(","))
                                    setClassicModal(true)
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit />
                            </Button>{" "}
                            <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => warningWithConfirmAndCancelMessage({id: prop.id})}
                                    color="danger"
                                    className="remove"
                                >
                                    <Close />
                                </Button>{" "}
                        </div>
                    )
                };
            }));
        }
    }
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    const formatDate2 = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            min = d.getMinutes()
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if (hour.length < 2) 
            hour = '0' + hour;
        if (min.length < 2) 
            min = '0' + min;
        return [year, month, day].join('-')+"T"+hour+":"+min;
    }
    const onSubmit = async (data) => {
        console.log("submit data ==>", data);
        if (isEmpty(selectedSquad)) {
            props.toastComponentAction.showErrorMessage(true, "Select sport is compulsary.")
        } else if (isEmpty(selectedEventType)) {
            props.toastComponentAction.showErrorMessage(true, "Select event type is compulsary.")
        } else {
           var final = {}
           final.squad_id = selectedSquad;
           final.type_id = selectedEventType;
           final.event_id = activeData.id;
           final.date = data.date
           final.home_team_name = data.home_team_name
           final.away_team_name = data.away_team_name
           final.meet_time = data.meet_time
           if(data.start_time){

               final.start_time = data.start_time
           }
           if(data.finish_time){

            final.finish_time = data.finish_time
        }
           final.location = data.location
           final.info = data.info
           final.fees = data.fees ? data.fees : 0
            console.log("final ==>", final);
            try {
                props.loaderActions.showLoader();
                var resp = await squadService.editEvent(final);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModal(false)
            } catch (err) {
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    const onSubmitAdd = async (data) => {
        console.log("submit data ==>", data);
        if (isEmpty(selectedSquad)) {
            props.toastComponentAction.showErrorMessage(true, "Select sport is compulsary.")
        } else if (isEmpty(selectedEventType)) {
            props.toastComponentAction.showErrorMessage(true, "Select event type is compulsary.")
        } else {
           var final = {}
           final.squad_id = selectedSquad;
           final.type_id = selectedEventType;
           final.date = data.date
           final.home_team_name = data.home_team_name
           final.away_team_name = data.away_team_name
           final.meet_time = data.meet_time
           if(data.start_time){

               final.start_time = data.start_time
           }
           if(data.finish_time){

            final.finish_time = data.finish_time
        }
           final.location = data.location
           final.info = data.info
           final.fees = data.fees ? data.fees : 0
            console.log("final ==>", final);
            try {
                props.loaderActions.showLoader();
                var resp = await squadService.createEvent(final);
                props.loaderActions.hideLoader();
                props.toastComponentAction.showSuccessMessage(true, resp.message)
                reload();
                setClassicModalAdd(false)
            } catch (err) {
                props.loaderActions.hideLoader();
                props.toastComponentAction.showErrorMessage(true, err.response.data.message)
            }
            console.log("create resp ==>", resp);
        }
    }
    const warningWithConfirmAndCancelMessage = (data) => {
        setAlert(
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => successDelete(data)}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classesalert.button + " " + classesalert.success}
            cancelBtnCssClass={classesalert.button + " " + classesalert.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            You will not be able to revert this!
          </SweetAlert>
        );
      };
      const successDelete = async (data) => {
        props.loaderActions.showLoader();
        var resp = await squadService.deleteEvent({event_id: data.id})
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message)
        reload();
        hideAlert();
      };
      const hideAlert = () => {
        setAlert(null);
      };
    return (
        <>
        {alert}
            <GridContainer
                justify="space-between" // Add it here :)
                container
            >
                <GridItem>
                </GridItem>
                <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={(e) => {
                            setSelectedColor("");
                            setSelectedSport("");
                            setSelectedLeague("");
                            setSelectedAdminList([])
                            setClassicModalAdd(true)
                            reset2()
                        }}>
                           Create Event
              </Button>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Event List</h4>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "Date",
                                        accessor: "date"
                                    },
                                    {
                                        Header: "Squad",
                                        accessor: "squad"
                                    },
                                    {
                                        Header: "Event Type",
                                        accessor: "event_type"
                                    },
                                    {
                                        Header: "Sport",
                                        accessor: "sport_name"
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "actions"
                                    }
                                ]}
                                data={data}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/*Edit Model Start*/}
            <Dialog
                fullWidth
                open={classicModal}
                keepMounted
                onClose={() => setClassicModal(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModal(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Edit Event</p>
                </DialogTitle>
                {Object.keys(activeData).length > 0 ? <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                    <FormControl fullWidth className={classes.formControl}>

                    <InputLabel>Select Squad</InputLabel>
                    <Select
                        MenuProps={{
                            className: classes.selectMenu
                        }}
                        classes={{
                            select: classes.select
                        }}

                        value={selectedSquad}
                        onChange={(e) => {
                            setSelectedSquad(e.target.value)
                        }}
                        inputProps={{
                            name: "simpleSelect"
                        }}
                        disabled
                    >
                        {squads && squads.length > 0 ?
                            squads.map(art => {
                                return <MenuItem
                                    key={art.id}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    value={art.id}
                                >
                                    {art.name}
                                </MenuItem>
                            })
                            : null}
                    </Select>
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>

                        <InputLabel>Select Event Type</InputLabel>
                        <Select
                            MenuProps={{
                                className: classes.selectMenu
                            }}
                            classes={{
                                select: classes.select
                            }}

                            value={selectedEventType}
                            onChange={(e) => {
                                setSelectedType(e.target.value)
                            }}
                            inputProps={{
                                name: "simpleSelect"
                            }}
                            disabled
                        >
                            {master && master.event_types && master.event_types.length > 0 ?
                                master.event_types.map(art => {
                                    return <MenuItem
                                        key={art.value}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={art.value}
                                    >
                                        {art.sport_name+ " " + art.label}
                                    </MenuItem>
                                })
                                : null}
                        </Select>
                        </FormControl>
                        <CustomInput
                            name="date"
                            inputrefs={register({ required: true })}
                            labelText="Date"
                            id="date"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "date",
                                autoComplete: "off",
                                defaultValue: formatDate(new Date(activeData.date))
                            }}
                        />
                        <CustomInput
                            name="home_team_name"
                            inputrefs={register({ required: true })}
                            labelText="Home Team Name"
                            id="home_team_name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: activeData.home_team_name
                            }}
                        />
                        <CustomInput
                            name="away_team_name"
                            inputrefs={register({ required: false })}
                            labelText="Away Team Name"
                            id="away_team_name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: activeData.away_team_name
                            }}
                        />
                        <CustomInput
                            name="meet_time"
                            inputrefs={register({ required: false })}
                            labelText="Meet Time"
                            id="meet_time"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "datetime-local",
                                autoComplete: "off",
                                defaultValue: formatDate2(new Date(activeData.meet_time))
                            }}
                        />
                        <CustomInput
                            name="start_time"
                            inputrefs={register({ required: false })}
                            labelText="Start Time"
                            id="start_time"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "datetime-local",
                                autoComplete: "off",
                                defaultValue: formatDate2(new Date(activeData.start_time)),
                            }}
                        />
                        <CustomInput
                            name="finish_time"
                            inputrefs={register({ required: false })}
                            labelText="Finish Time"
                            id="finish_time"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "datetime-local",
                                autoComplete: "off",
                                defaultValue: formatDate2(new Date(activeData.final_time)),
                            }}
                        />
                        <CustomInput
                            name="location"
                            inputrefs={register({ required: false })}
                            labelText="Location"
                            id="location"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: activeData.location
                            }}
                        />
                        <CustomInput
                            name="info"
                            inputrefs={register({ required: false })}
                            labelText="Info"
                            id="info"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: activeData.indo
                            }}
                        />
                        <CustomInput
                            name="fees"
                            inputrefs={register({ required: false })}
                            labelText="Fees"
                            id="fees"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "number",
                                autoComplete: "off",
                                defaultValue: activeData.fees
                            }}
                        />
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                        {
                            activeData ?
                                <Button type="submit" color="transparent">Submit</Button> : ''
                        }
                        <Button
                            onClick={() => setClassicModal(false)}
                            color="danger"
                            simple
                        >
                            Close
     </Button>
                    </DialogActions>
                </form> : null}

            </Dialog>
            {/*Edit Model End*/}
            {/*Add Model Start*/}
            <Dialog
                fullWidth
                open={classicModalAdd}
                keepMounted
                onClose={() => setClassicModalAdd(false)}
                aria-labelledby="form-dialog-title"

            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModalAdd(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Create Event</p>
                </DialogTitle>
                <form onSubmit={handleSubmit2(onSubmitAdd)}>
                    <DialogContent>
                    <FormControl fullWidth className={classes.formControl}>

                    <InputLabel>Select Squad</InputLabel>
                    <Select
                        MenuProps={{
                            className: classes.selectMenu
                        }}
                        classes={{
                            select: classes.select
                        }}

                        value={selectedSquad}
                        onChange={(e) => {
                            setSelectedSquad(e.target.value)
                        }}
                        inputProps={{
                            name: "simpleSelect"
                        }}

                    >
                        {squads && squads.length > 0 ?
                            squads.map(art => {
                                return <MenuItem
                                    key={art.id}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    value={art.id}
                                >
                                    {art.name}
                                </MenuItem>
                            })
                            : null}
                    </Select>
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>

                        <InputLabel>Select Event Type</InputLabel>
                        <Select
                            MenuProps={{
                                className: classes.selectMenu
                            }}
                            classes={{
                                select: classes.select
                            }}

                            value={selectedEventType}
                            onChange={(e) => {
                                setSelectedType(e.target.value)
                            }}
                            inputProps={{
                                name: "simpleSelect"
                            }}

                        >
                            {master && master.event_types && master.event_types.length > 0 ?
                                master.event_types.map(art => {
                                    return <MenuItem
                                        key={art.value}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={art.value}
                                    >
                                        {art.sport_name+ " " + art.label}
                                    </MenuItem>
                                })
                                : null}
                        </Select>
                        </FormControl>
                        <CustomInput
                            name="date"
                            inputrefs={register2({ required: true })}
                            labelText="Date"
                            id="date"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "date",
                                autoComplete: "off",
                                defaultValue: formatDate(new Date())
                            }}
                        />
                        <CustomInput
                            name="home_team_name"
                            inputrefs={register2({ required: true })}
                            labelText="Home Team Name"
                            id="home_team_name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off"
                            }}
                        />
                         <CustomInput
                            name="away_team_name"
                            inputrefs={register2({ required: false })}
                            labelText="Away Team Name"
                            id="away_team_name"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off"
                            }}
                        />
                         <CustomInput
                            name="meet_time"
                            inputrefs={register2({ required: false })}
                            labelText="Meet Time"
                            id="meet_time"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "datetime-local",
                                autoComplete: "off",
                                defaultValue: formatDate2(new Date())
                            }}
                        />
                         <CustomInput
                            name="start_time"
                            inputrefs={register2({ required: false })}
                            labelText="Start Time"
                            id="start_time"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "datetime-local",
                                autoComplete: "off",
                                defaultValue: formatDate2(new Date())
                            }}
                        />
                         <CustomInput
                            name="finish_time"
                            inputrefs={register2({ required: false })}
                            labelText="Finish Time"
                            id="finish_time"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "datetime-local",
                                autoComplete: "off",
                                defaultValue: formatDate2(new Date())
                            }}
                        />
                        <CustomInput
                            name="location"
                            inputrefs={register2({ required: false })}
                            labelText="Location"
                            id="location"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off"
                            }}
                        />
                        <CustomInput
                            name="info"
                            inputrefs={register2({ required: false })}
                            labelText="Info"
                            id="info"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off"
                            }}
                        />
                        <CustomInput
                            name="fees"
                            inputrefs={register2({ required: false })}
                            labelText="Fees"
                            id="fees"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "number",
                                autoComplete: "off"
                            }}
                        />
                    </DialogContent>

                    <DialogActions className={classes.modalFooter}>
                        <Button type="submit" color="transparent">Submit</Button>
                        <Button
                            onClick={() => setClassicModalAdd(false)}
                            color="danger"
                            simple
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>


            </Dialog>
            {/*Add Model End*/}
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
        squadAction: bindActionCreators(squadAction, dispatch),
        loaderActions: bindActionCreators(loaderActions, dispatch),
        toastComponentAction: bindActionCreators(toastComponentAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SquadContainer);
