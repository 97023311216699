import React, { useEffect, lazy, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import masterService from "../../services/apiService";
import userService from "../../services/user";
import { useForm } from "react-hook-form";
import {default as CustomInput} from "components/SquadTypeCustomInput/SquadTypeCustomInput.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import cmsService from "../../services/cms";
import Badge from "components/Badge/Badge";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import parse from 'html-react-parser'
const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ "components/ReactTable/ReactTable.js"
  )
);

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2,3,4,5,6, false]} ],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }]
  ],
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "size",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
];
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertstyles);
const CMSContainer = (props) => {
  const [alert, setAlert] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [master, setMaster] = React.useState({});
  const [leagues, setLeagues] = React.useState([]);
  const [adminList, setAdminList] = React.useState([]);
  const { register: register2, handleSubmit: handleSubmit2 } = useForm();
  const [classicModalAdd, setClassicModalAdd] = React.useState(false);
  const [classicEditModal, setClassicEditModal] = React.useState(false);
  const [classicCMSModal, setClassicCMSModal] = React.useState(false);

  const [textQuillStandart, setTextQuillStandart] = useState({content:""});
  const [title, setTitle] = useState("");
  const [cmsKey, setCmsKey] = useState("");

  const classes = useStyles();
  const classesalert = useStylesAlert();
  useEffect(() => {
    (async () => {
      var cmslist = await cmsService.getcmslist();
      if (cmslist && cmslist.data && cmslist.data.length > 0) {
        setData(
          cmslist.data.map((prop, key) => {
            return {
              id: key,
              title:prop.title,
              content: <div className="actions-left" key={key}><Button
              // className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              addButtonProps={{
                color: "rose",
                round: true,
              }}
              onClick={() => {
                setTitle(prop.title);
                setCmsKey(prop.cms_key);
                setTextQuillStandart({content:prop.content});
                setClassicCMSModal(true)
              }}
            >
              Show Content
            </Button></div>,
              cms_key: prop.cms_key,
              actions: (
                // we've added some custom button actions
                <div className="actions-right" key={key}>
                  <Button
                    justIcon
                    round
                    simple
                    color="warning"
                    className="edit"
                    onClick={() => {
                      setTitle(prop.title);
                      setCmsKey(prop.cms_key);
                      setClassicEditModal(true);
                      setTextQuillStandart({content:prop.content});
                    }}
                  >
                    <Edit />
                  </Button>
                </div>
              ),
            };
          })
        );
      }

      var masterData = await masterService.getMasterData();
      if (masterData && masterData.data) {
        setMaster(masterData.data);
      }
      var leagueData = await masterService.getLeagueList();
      if (leagueData && leagueData.data) {
        setLeagues(leagueData.data);
      }
      var userData = await userService.getUserList();
      if (userData && userData.data) {
        setAdminList(userData.data);
      }
    })();
  }, []);
  const reload = async () => {
    var cmslist = await cmsService.getcmslist();
      if (cmslist && cmslist.data && cmslist.data.length > 0) {
        setData(
          cmslist.data.map((prop, key) => {
            return {
              id: key,
              title:prop.title,
              content:<div className="actions-left" key={key}><Button
              // className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              addButtonProps={{
                color: "rose",
                round: true,
              }}
              onClick={() => {
                setTitle(prop.title);
                setCmsKey(prop.cms_key);
                setTextQuillStandart({content:prop.content});
                setClassicCMSModal(true)
              }}
            >
              Show Content
            </Button></div>,
              cms_key: prop.cms_key,
              status: (
                <div style={{textAlign:"center"}}><Badge color={prop.status === 1?"success":"danger"}>{prop.status === 1 ? "Active":"Deleted"}</Badge></div>
              ),
              actions: (
                // we've added some custom button actions
                <div className="actions-right" key={key}>
                  <Button
                    justIcon
                    round
                    simple
                    color="warning"
                    className="edit"
                    onClick={() => {
                      setTitle(prop.title);
                      setCmsKey(prop.cms_key);
                      setClassicEditModal(true);
                      setTextQuillStandart({content:prop.content});
                    }}
                  >
                    <Edit />
                  </Button>
                </div>
              ),
            };
          })
        );
      }
  };
  const OnEditFunction = async (e) => {

    if(title.trim() == ""){
      props.toastComponentAction.showErrorMessage(
        true,
        "Title is Required."
      );
   }else if(textQuillStandart.content.trim().replace(/<[^>]+>/g, '') == ""){
    props.toastComponentAction.showErrorMessage(
      true,
      "Edtior is Blank."
    );
  }else{
    const editSubmitCMS={
     title:title,
     content:textQuillStandart.content,
     cms_key:cmsKey
    }
     try {
       props.loaderActions.showLoader();
       var resp = await cmsService.updateCMS(editSubmitCMS)
       props.loaderActions.hideLoader();
       props.toastComponentAction.showSuccessMessage(true, resp.message);
       reload();
       setClassicEditModal(false);
     } catch (err) {
       props.loaderActions.hideLoader();
       props.toastComponentAction.showErrorMessage(
         true,
         err.response.data.message
       );
     }
   }
  };

  // const onSubmitAdd = async (data) => {
  //    if(title.trim() == ""){
  //       props.toastComponentAction.showErrorMessage(
  //         true,
  //         "Title is Required."
  //       );
  //    }else if(textQuillStandart.content.trim() == ""){
  //     props.toastComponentAction.showErrorMessage(
  //       true,
  //       "Edtior is Blank."
  //     );
  //    }else{
  //      const submitData ={
  //        title:title,
  //        content:textQuillStandart.content,
  //        cms_key:cmsKey,
  //      }
      
  //      try {
  //        props.loaderActions.showLoader();
  //        var resp = await cmsService.createCMS(submitData);
  //        props.loaderActions.hideLoader();
  //        props.toastComponentAction.showSuccessMessage(true, resp.message);
  //        reload();
  //        setClassicModalAdd(false);
  //      } catch (err) {
  //        props.loaderActions.hideLoader();
  //        props.toastComponentAction.showErrorMessage(
  //          true,
  //          err.response.data.message
  //        );
  //      }
  //    }
  // };



  return (
    <>
     {alert}
      <GridContainer
        justify="space-between" // Add it here :)
        container
        >
        <GridItem></GridItem>
        {/* <GridItem>
          <div>
            <Button
              color="primary"
              className={classes.marginRight}
              onClick={(e) => {
                setSelectedColor("");
                setSelectedSport("");
                setSelectedLeague("");
                setSelectedAdminList([]);
                setClassicModalAdd(true);
                setSquadEvent([
                  { id: 1, event_name: "", event_type: "", event_image: "",image_url:"" },
                ])
                setSquadStat([{squad_stat:""}])
              }}
              >
              Add CMS
            </Button>
          </div>
        </GridItem> */}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>CMS List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Title",
                    accessor: "title",
                  },
                  {
                    Header: "Content",
                    accessor: "content",
                  },
                  {
                    Header: "CMS Key",
                    accessor: "cms_key",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={data}
                />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Edit Model Start*/}
      <Dialog
        fullWidth
        open={classicEditModal}
        keepMounted
        onClose={() => {
          setTitle("") 
          setCmsKey("")
          setTextQuillStandart({ content: "" })
          setClassicEditModal(false)
        }}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
          
            onClick={() => {
              setTitle("") 
              setCmsKey("")
              setTextQuillStandart({ content: "" })
              setClassicEditModal(false)
            }}
            >
            <Close className={classes.modalClose} />
          </Button>
          <p>Edit CMS</p>
        </DialogTitle>
        <form onSubmit={(e)=>{
             e.preventDefault()
             OnEditFunction()
          }}>
          <DialogContent>
          
            <CustomInput
              name="title"
              inputrefs={register2({ required: true, validate:(value)=>{
                if(value.trim() === ""){
                  return "* Mandatory Field"
                }
              } })}
              labelText="Title"
              id="title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                autoComplete: "off",
              }}
              defaultValue=""
              value={title}
              onChange={(e)=>setTitle(e.target.value)}
              />
                <ReactQuill
                theme="snow"
                value={textQuillStandart.content}
                onChange={(val) => {
                  setTextQuillStandart({ ...textQuillStandart, content: val });
                }}
                modules={quillModules}
                formats={quillFormats}
                className={"reactquills"}
              />

          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button type="submit" color="transparent">
              Submit
            </Button>
            <Button
              onClick={() =>{
                setTitle("") 
                setCmsKey("")
                setTextQuillStandart({ content: "" })
                setClassicEditModal(false)
              }}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/*Edit Model End*/}

{/*Show CMS Content Start*/}
<Dialog
        fullWidth
        open={classicCMSModal}
        keepMounted
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setClassicCMSModal(false)
            }}
            >
            <Close className={classes.modalClose} />
          </Button>
          <p>{title}</p>
        </DialogTitle>
       
          <DialogContent>
          
              <div>
                {parse(textQuillStandart.content)}
              </div>

          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button type="submit" color="primary" onClick={()=>{
              setClassicCMSModal(false)
              setClassicEditModal(true)
            }}>
              EDIT
            </Button>
          </DialogActions>
      </Dialog>
      {/*Show CMS Content END*/}




      {/*Add Model Start*/}
      {/* <Dialog
        fullWidth
        open={classicModalAdd}
        keepMounted
        onClose={() => {
          setSquadEvent([
            { id: 1, event_name: "", event_type: "", event_image: "",image_url:"" },
          ])
          setSquadStat([
            {  squad_stat: ""},
          ])
          setSelectedSport("")
          setClassicModalAdd(false)
          setSubmitted(true)
        }}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicModalAdd(false)}
            >
            <Close className={classes.modalClose} />
          </Button>
          <p>Add CMS</p>
        </DialogTitle>
        <form onSubmit={(e)=>{
             e.preventDefault()
              onSubmitAdd()
          }}>
          <DialogContent>
          
            <CustomInput
              name="title"
              inputrefs={register2({ required: true, validate:(value)=>{
                if(value.trim() === ""){
                  return "* Mandatory Field"
                }
              } })}
              labelText="Title"
              id="title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                autoComplete: "off",
              }}
              defaultValue=""
              value={title}
              onChange={(e)=>setTitle(e.target.value)}
              />
               <CustomInput
              name="cms_key"
              inputrefs={register2({ required: true, validate:(value)=>{
                if(value.trim() === ""){
                  return "* Mandatory Field"
                }
              } })}
              labelText="CMS Key"
              id="cms_key"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                autoComplete: "off",
              }}
              defaultValue=""
              value={cmsKey}
              onChange={(e)=>setCmsKey(e.target.value)}
              />
                <ReactQuill
                theme="snow"
                value={textQuillStandart.content}
                onChange={(val) => {
                  setTextQuillStandart({ ...textQuillStandart, content: val });
                }}
                modules={quillModules}
                formats={quillFormats}
                className={"reactquills"}
              />

          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button type="submit" color="transparent">
              Submit
            </Button>
            <Button
              onClick={() => setClassicModalAdd(false)}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}
      {/*Add Model End*/}

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CMSContainer);