// import { history } from '../redux/history';

import { request } from "utilities/request.js";



function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Authorization':  getUserToken()
  }, data || {});
}

function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = "Bearer " + token;

  return bearerToken;
}


const getcmslist = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/cms/all_cms';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const createCMS = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/cms/add_cms';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const updateCMS = (data)=>{
  let url = process.env.REACT_APP_API_HOST + `/admin/cms/edit_cms`;
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const apiService = {
    getcmslist,
    createCMS,
    updateCMS
};

export default apiService;